import { SortItem } from '@/types/SortItem';
import { DEFAULT_ROWS_ON_PAGE, LOCALSTORAGE_KEYS } from '@/utils/constants';
import localStorage from '@/utils/localStorage';

export default function () {
  function handleUpdatePage(table: string, value: number): void {
    const currentTableParams = localStorage.getObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS) ?? {};
    if (table in currentTableParams) {
      currentTableParams[table].page = value;
    } else {
      currentTableParams[table] = { ...currentTableParams[table], page: value };
    }
    localStorage.setObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS, currentTableParams);
  }

  function handleUpdatePageSize(table: string, value: number): void {
    const currentTableParams = localStorage.getObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS) ?? {};
    if (table in currentTableParams) {
      currentTableParams[table].size = value;
    } else {
      currentTableParams[table] = { ...currentTableParams[table], size: value };
    }
    localStorage.setObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS, currentTableParams);
  }

  function handleUpdateOrder(table: string, value: SortItem): void {
    const currentTableParams = localStorage.getObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS) ?? {};
    if (table in currentTableParams) {
      currentTableParams[table].order = value;
    } else {
      currentTableParams[table] = { ...currentTableParams[table], order: value };
    }
    localStorage.setObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS, currentTableParams);
  }

  function getTablePage(table: string): number {
    const currentTableParams = localStorage.getObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS) ?? {};    
    return currentTableParams[table]?.page ? currentTableParams[table].page : 1;
  }

  function getTablePageSize(table: string): number {
    const currentTableParams = localStorage.getObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS) ?? {};
    return currentTableParams[table]?.size ? currentTableParams[table].size : DEFAULT_ROWS_ON_PAGE;
  }

  function getTableOrder(table: string): SortItem {
    const currentTableParams = localStorage.getObject(LOCALSTORAGE_KEYS.PAGINATION_TABLE_PARAMS) ?? {};   
    return currentTableParams[table]?.order ? currentTableParams[table].order : {};
  }

  return {
    handleUpdatePage,
    handleUpdatePageSize,
    handleUpdateOrder,
    getTablePage,
    getTablePageSize,
    getTableOrder
  };
}
