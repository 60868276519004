
export default {
  name: 'SearchInput',
  inheritAttrs: true,
  computed: {
    computedProps() {
      return {
        ...this.$attrs
      };
    }
  }
};
